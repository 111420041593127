import MediaBackground from "../../components/mediaBackground/MediaBackground";
import Expert_EngineersBgImage from '../../public/headers/Expert Engineers.png';
import Expert_EngineersImage from '../../public/Expert Engineers.png';
import Expert_EngineersIcon from '../../public/ExpertEngineersIcon.png';
import Section from "../../components/section/Section";
import SectionContainer from "../../components/section/SectionContainer";
import Blurb from "../../components/blurb/Blurb";
import PageTitle from "../../components/pageTitle/PageTitle";
import ImageOrVideoWithText from "../../components/imageWithText/ImageOrVideoWithText";

const Expertengineers = () => {
  return (
    <>
      <div className="ExpertEngineers">
        <PageTitle title="Expert Engineers" showBg={true} bgType="image" bgData={Expert_EngineersBgImage} />
        <Section>
          <SectionContainer>
            <Blurb img={Expert_EngineersIcon}>
            Our expert Engineers design and build systems with a focus on serviceability, data collection, and adaptive learning. 
            </Blurb>
          </SectionContainer>
        </Section>

        <Section>
            <ImageOrVideoWithText src={Expert_EngineersImage}>
              <b>Engineers at Remotion are given cradle to grave responsibility of engineered systems.</b> This helps ensure seamless integration of all sub-systems including safety, robotics, mechanics, electrics, sequence control, and human/machine interaction.   
              Remotion's engineering group has an extensive background in lean manufacturing. <b>The engineering team's combined experience allows us to supply robust, efficient, production-ready systems.</b> This greatly reduces or eliminates the need for our industry leading customers to focus continuous improvement efforts on systems supplied by Remotion.
            </ImageOrVideoWithText>
        </Section>

      </div>
    </>
  )
}

export default Expertengineers
