import React from 'react'
import MediaBackground from '../mediaBackground/MediaBackground';
import SectionContainer from '../section/SectionContainer';


//CardData takes a list of objects with the following properties:
//title: string
//subtitle: string  
//image: string

const CardSection = ({ Title, CardData }) => {

  return (
    <SectionContainer>
      <div className="CardSection">
        <h1>{Title}</h1>
        <div className="cards-container">
          {
            CardData.map((card, index) => {
              return (
                <div className="card" key={index}>
                  <div className="triangle-thing" />
                  <MediaBackground type="image" source={card.image} showOverlay={true} showDivider={false} position={'relative'}>
                    <h2>{card.title}</h2>
                  </MediaBackground>
                  {card.subtitle && <div>{card.subtitle}</div>}
                </div>
              )
            })
          }
        </div>
      </div>
    </SectionContainer>
  )
}

export default CardSection