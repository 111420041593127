import ContactUs from "../../components/contactUs/ContactUs";
import PageTitle from "../../components/pageTitle/PageTitle";
import ProcessSection from "../../components/processSection/ProcessSection";
import video from "../../public/Videos/banner-video.mp4";
const Contact = () => {
  return (
    <>
      <div className="Contact">
        <div className="title-section">
          <PageTitle title="Get Started Today" showBg={true} bgData={video} bgType="video"/>
        </div>
        <ProcessSection showProcessHeader={false} />
        <ContactUs/>
      </div>
    </>
  )
}

export default Contact