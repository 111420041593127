import React from 'react'
import TriangleDivider from '../triangleDivider/TriangleDivider';

const MediaBackground = (props) => {
  const {
    source, // video or image
    showDivider, //Show the divider
    showOverlay, //Show the overlay
    type, // video or image
    pos // absolute or relative
  } = props;
  return (
    <div className={`MediaBackground ${type}`} style={{ position: pos }}>
      {<div className={showOverlay ? "overlay" : ''}>
        {showDivider && <TriangleDivider />}
      </div>}

      <div className='background-content'>
        {props.children}
      </div>
      
      {type === 'video' ?
        <div className="video-wrapper">
          <video autoPlay loop muted playsInline>
            <source src={source} type="video/mp4" />
          </video>
        </div>
        :
        <div className="image-wrapper" style={{backgroundImage: `url('${source}')`}} />
      }
    </div>
  )
}

export default MediaBackground