import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import logo from '../../public/logo.png';
import Navigation from '../navigation/Navigation';


const Header = () => {

  
  const [navOpen, setNavOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);

  const handleNavOpen = () => {
      setNavOpen(!navOpen);
  }

  useEffect(() => {
    if(window.innerWidth > 768) //If the website is wider than 768px, the navigation is always open 
      setNavOpen(true);
}, [])

  return (
    <header className="Header">
      <div className="logo-container">
        <Link to="/">
          <img src={logo} className="logo" alt="logo" />
        </Link>
      </div>
      <Navigation header={true} navOpen={navOpen}/>
                
                
      <div className="hamburger-menu" onClick={handleNavOpen}>
          <div className="rectangle"></div>
          <div className="rectangle"></div>
          <div className="rectangle"></div>
      </div>
    </header>
  )
}

export default Header
