import React from 'react'
import TriangleDivider from '../triangleDivider/TriangleDivider';

const PageTitleMediaBackground = (props) => {
  const {
    source, // video or image
    showDivider, //Show the divider
    showOverlay, //Show the overlay
    type, // video or image
    pos // absolute or relative
  } = props;
  return (
    <div className="PageTitleMediaBackground" style={{ position: pos }}>
      {<div className={showOverlay ? "overlay" : ''}>
        {showDivider && <TriangleDivider />}
      </div>}
      {type === 'video' ?
        <div className="video-wrapper">
          <video autoPlay loop muted playsInline>
            <source src={source} type="video/mp4" />
          </video>
        </div>
        :
        <div className="image-wrapper">
          <img src={source} alt="" />
        </div>
      }
    </div>
  )
}

export default PageTitleMediaBackground