import { Link } from 'react-router-dom';
import logo from '../../public/logo.png';
import linkedinlogo from '../../public/linkedin-logo.png';
import youtubelogo from '../../public/youtube-logo.png';
import Navigation from '../navigation/Navigation';


function Footer() {
  return (
    <div className="Footer">
      <div className="footer-content">
        <div className="section">
          <Link to="/">
            <img src={logo} className="logo" alt="logo" />
          </Link>
        </div>

        <div className="section">
          <div className="address-section">
            <div className='inner-address-container'>
              <h5>Address</h5>
              <p>3404 12th Street</p>
              <p>Wayland, MI 49348</p>
            </div>
            <div className='inner-address-container'>
              <h5>Phone</h5>
              <p>(734) 770-9669</p>
            </div>
            <div className='inner-address-container'>
              <h5>Email</h5>
              <p>sales@consultremotion.com</p>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="follow-section">
            <b>Follow Us</b>
            <div className='follow-links'>
              <a href="https://www.linkedin.com/company/consultremotion/">
                <img alt="LinkedIn" src={linkedinlogo} />
              </a>
              <a href="https://www.youtube.com/channel/UCnGQO-NDiLqIVlZcj_4iULQ">
                <img alt="Youtube" src={youtubelogo} />
              </a>
            </div>
            <Navigation vertical={true} />
          </div>
        </div>
      </div>

    </div>
  );
}

export default Footer;
