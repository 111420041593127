
import fuelSystemBgImage from '../../public/headers/FUEL-SYSTEMS-AUTOMOTIVE.png';
import medicalBgImage from '../../public/headers/Medical-devices.png';
import aircraftBgImage from '../../public/headers/Aircraft.png';
import otherBgImage from '../../public/headers/Other.png';
import grindingVideo from "../../public/Videos/grinding-vid.mp4";
import GrindingCapabilitiesImage from '../../public/GrindingCapabilities.png';
import GrindingSolutionsIcon from '../../public/GrindingSolutionsIcon.png';
import Section from "../../components/section/Section";
import PageTitle from "../../components/pageTitle/PageTitle";
import SectionContainer from "../../components/section/SectionContainer";
import Blurb from "../../components/blurb/Blurb";
import ImageOrVideoWithText from "../../components/imageWithText/ImageOrVideoWithText";
import CardSection from "../../components/cardSection/CardSection";

const GrindingSolutions = () => {
  return (
    <>
      <div className="GrindingSolutions">
        <PageTitle title="Grinding Solutions" showBg={true} bgType="video" bgData={grindingVideo} />

        <Section>
          <SectionContainer>
            <Blurb img={GrindingSolutionsIcon}>
              Our strength and experience is in centerless, cylindrical, and ID grinding. We can engineer automated, mass-production applications as well as flexible, high part variant manufacturing cells.
            </Blurb>
          </SectionContainer>
        </Section>

        <Section>
          <ImageOrVideoWithText src={GrindingCapabilitiesImage}>
            <h2>CAPABILITIES</h2>
            <ul>
              <li>Centerless Grinding</li>
              <li>Precision ID Grinding</li>
              <li>Cylindrical Grinding</li>
              <li>Machine Refurbishment and Repurpose</li>
              <li>Control Upgrades</li>
            </ul>
          </ImageOrVideoWithText>
        </Section>

        <div className="section">
          <CardSection PageTitle="Industries Served - Grinding Solutions" CardData={[
            {
              title: "FUEL SYSTEMS & AUTOMOTIVE",
              subtitle: "We have a strong presence in the precision fuel component industry.  Match grinding, post-process gaging, automation, and inspection are all common requests regarding these applications.",
              image: fuelSystemBgImage
            },
            {
              title: "MEDICAL DEVICE",
              subtitle: "Innovative methods for high material removal rates and the automation and inspection required to accompany these applications has given us a niche in the medical instrument market.",
              image: medicalBgImage
            },
            {
              title: "AIRCRAFT",
              subtitle: "From precision hydraulic components to fasteners, we have experience automating, inspecting, and grinding difficult to machine materials.",
              image: aircraftBgImage
            },
            {
              title: "OTHER",
              subtitle: "Mass production thread rolling and inspection has become a core strength.",
              image: otherBgImage
            }
          ]} />
        </div>
      </div>
    </>
  )
}

export default GrindingSolutions
