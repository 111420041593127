import MediaBackground from "../../components/mediaBackground/MediaBackground";
import video from '../../public/Videos/banner-video.mp4';
import GreenButton from "../../components/button/GreenButton";
//Image import
import grinding from '../../public/grinding-wheel.png';
import gear from '../../public/automation-gear.png';
import hardhat from '../../public/worker-with-hardhat.png';
import bigone from '../../public/giant-remotion-image-resize.png';
import whyremotionbackground from '../../public/why-remotion-background.png';
import micronlogo from '../../public/micron-card.png';
import shigiyacard from '../../public/shigiya-card.png';
import regginspectioncard from '../../public/regginspection-card.png';
import ContactUs from "../../components/contactUs/ContactUs";
import ProcessSection from "../../components/processSection/ProcessSection";
import DesktopOnly from "../../components/desktopOnly/DesktopOnly";
import SectionContainer from "../../components/section/SectionContainer";

const Home = () => {
  return (
    <>
      <div className="Home">
        <div className="section" id="video-section">
          <MediaBackground type="video" source={video} showOverlay={true} showDivider={true} position={'relative'}>
            <SectionContainer>
              <h1>Manufacturing Process, Turnkey, and Automation Specialists</h1>
              <DesktopOnly>
                Combined with our OEM partners, we have decades of turnkey and automation experience in grinding, automation, and inspection. Our core process and turnkey strengths are in centerless, ID, and cylindrical grinding. However, our experience with automating grinders has expanded our offering to automated inspection, machine tending, and general CNC automation.
              </DesktopOnly>
              <GreenButton to="contact" title={"Get Started"} fullWidth={false} />
            </SectionContainer>
          </MediaBackground>
        </div>

        <div className="section" id="card-section">
          <div className="card">
            <img src={grinding} alt="" />
            <h1>Grinding Solutions</h1>
            <p>Our strength and experience is in centerless, cylindrical, and ID grinding.  Our staff, combined with our OEM partners, can engineer automated, mass-production applications as well as flexible, high part variant manufacturing cells.</p>
            <GreenButton to="grinding-solutions" title={"Learn More"} />
          </div>
          <div className="card">
            <img src={gear} alt="" />
            <h1>Automation & Inspection</h1>
            <p>As our industry continues to trend towards automated material handling and inspection – so do we.  We have vast experience with both mass-production dedicated manufacturing cells and easy to program flexible cells designed for frequent changeover.</p>
            <GreenButton to="automation-inspection" title={"Learn More"} />
          </div>
          <div className="card">
            <img src={hardhat} alt="" />
            <h1>Expert Engineers</h1>
            <p>We endeavor to design and build systems that are not only mechanically well thought out, but also incorporate ‘best practice’ controls engineering with a focus on serviceability, data collection, and adaptive learning.</p>
            <GreenButton to="expert-engineers" title={"Learn More"} />
          </div>
        </div>

        <div className="section" id="why-section">
          <MediaBackground type="image" source={whyremotionbackground} showOverlay={false} pos={"absolute"} />
          <div className="row-section" id="left">
            <div className="img-container">
              <img className="large-remotion-logo" src={bigone} alt="" />
            </div>
          </div>
          <div className="row-section" id="right">
            <h1 className="title">Why Remotion?</h1>
            <p>Forthright, honest, communication. From sales and project management to service - we strive to be direct, transparent, and truthful. Our individual team members are well rounded and can speak to all aspects of a ‘turnkey’ system.</p>
          </div>
        </div>

        <div className="section" id="partners-section">
          <h1>Machine Tool Partners</h1>
          <div className="cards">
            <div className="card">
              <div className="card-inner">
                <img alt="Micron Logo" src={micronlogo} />
              </div>
              <div className="card-inner">
                <span>Precision Centerless and ID Grinders</span>
              </div>
            </div>
            <div className="card">
              <img alt="Shigiya Card" src={shigiyacard} />
              <span>Flexible Cylindrical and Universal Grinders</span>
            </div>
            <div className="card">
              <img alt="Regg Inspection Card" src={regginspectioncard} />
              <span>High Volume Part Inspection and Rolling</span>
            </div>
          </div>
        </div>

        <ProcessSection showProcessHeader={true} />
        <ContactUs />

      </div>
    </>
  )
}

export default Home