import { Link, NavLink } from 'react-router-dom';
import React from 'react'

const Navigation = (props) => {
    const { vertical, header, navOpen } = props;
    let navStyle = 'Navigation';

    if (vertical) {
        navStyle += ' vertical';
    }

    if (header) {
        navStyle += ' header';
    }
    navStyle += navOpen ? ' open' : ' closed';

    return (
        <>
            <ul className={navStyle}>
                <li>
                    <NavLink to={'about-us'} className={state => state.isActive ? 'active' : 'inactive'}>
                        About&nbsp;<br/>Us
                    </NavLink>
                </li>
                <li>
                    <NavLink to={'grinding-solutions'} className={state => state.isActive ? 'active' : 'inactive'}>
                        Grinding Solutions
                    </NavLink>
                </li>
                <li>
                    <NavLink to={'automation-inspection'} className={state => state.isActive ? 'active' : 'inactive'}>
                        Automation &&nbsp;<br/>Inspection
                    </NavLink>
                </li>
                <li>
                    <NavLink to={'expert-engineers'} className={state => state.isActive ? 'active' : 'inactive'}>
                        Expert&nbsp;<br/>Engineers
                    </NavLink>
                </li>
                <li>
                    <NavLink to={'whats-new'} className={state => state.isActive ? 'active' : 'inactive'}>
                        What's New
                    </NavLink>
                </li>
                {
                    !vertical && <li className="button">
                        <Link to="contact">Contact Us</Link>
                    </li>
                }
            </ul>
        </>
    )
}

export default Navigation
