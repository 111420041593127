
const DesktopOnly = (props) => {
  return (
    <div className="DesktopOnly">
      {props.children}
    </div>
  );
}

export default DesktopOnly;

