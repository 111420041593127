import Blog from "../../components/blog/Blog";
import PageTitle from "../../components/pageTitle/PageTitle";
import Section from "../../components/section/Section";
import whatsnewbg from '../../public/headers/whats-new-bg.png';

//Blog stuff
import imts from '../../public/blog-assets/imts-2022.jpg'
import { useState } from "react";
import { useEffect } from "react";

const blogData = [
  {
    title: "International Manufa cturing Technology Show 2022",
    date: "08/04/2022",
    content:{
      text: '',
      image: imts
    }
  }
];

const WhatsNew = () => {
  const [ blogEntries, setBlogEntries] = useState([]);
  const [ page, setPage ] = useState(1);
  const [ totalPages, setTotalPages ] = useState(0);

  useEffect(() => {
    setBlogEntries([]);
    fetch(`https://remotion-api.azurewebsites.net/api/blog-posts?sort[0]=Posted%3Adesc&pagination[page]=${page}&pagination[pageSize]=10&pagination[withCount]=true`, {
      'Content-Type': 'application/json'
    }).then((resp) => {
      resp.json().then((json) => {
        console.log(json);
        if (json.data) {
          setBlogEntries([...blogEntries, ...json.data]);
          setTotalPages(json.meta.pagination.total);
        }
      });
    })
  }, [page]);

  return (
    <>
      <div className="WhatsNew">
        <PageTitle title="What's New" showDivider={true} showBg={true} bgType="image" bgData={whatsnewbg} />
        <Section>
          <Blog blogData={blogEntries} />
          {page < totalPages && <div>
            <a className="blog-load-more" onClick={() => setPage(page + 1)}>Load More</a>
          </div>}
        </Section>
      </div>
    </>
  )
}

export default WhatsNew