
import Section from '../section/Section';

import React, { useState } from 'react'
import MediaBackground from '../mediaBackground/MediaBackground';
import micronBackground from '../../public/micron-background.png';
import hardHatPeople from '../../public/hardhat-people-talking.png';
import GreenButton from '../button/GreenButton';

//This component is used in both the ContactUs and Home views.

const ContactUs = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    company: '',
    email: '',
    phone: '',
    address_one: '',
    address_two: '',
    city: '',
    state: '',
    zip: '',
    body: ''
  })
  return (
    <div className="ContactUs">
      <Section background={micronBackground}>
        <div className="section" id="contact-form">
          <div className="horizontal-container">

            <div className="inner-container image-container">
              <img src={hardHatPeople} alt="" />
            </div>

            <div className="inner-container contact-form">
              <h1>Contact Form</h1>
              <form>

                <div className="form-group">
                  <div className="group">
                    <label htmlFor="first-name">First Name*</label>
                    <input type="text" className="form-control" id="first-name"
                      onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
                    />
                  </div>
                  <div className="group">
                    <label htmlFor="last-name">Last Name*</label>
                    <input type="text" className="form-control" id="last-name"
                      onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="group">
                    <label htmlFor="company">Company</label>
                    <input type="text" className="form-control" id="company"
                      onChange={(e) => setFormData({ ...formData, company: e.target.value })} //TODO: add company field
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="group">
                    <label htmlFor="email">Email*</label>
                    <input type="email" className="form-control" id="email"
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })} 
                    />
                  </div>
                  <div className="group">
                    <label htmlFor="phone">Phone Number*</label>
                    <input type="phone" className="form-control" id="phone"
                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="group">
                    <label htmlFor="address-one">Address 1</label>
                    <input type="text" className="form-control" id="address-one" 
                    onChange={(e) => setFormData({ ...formData, address_one: e.target.value })} //TODO: add field
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="group">
                    <label htmlFor="address-two">Address 2</label>
                    <input type="text" className="form-control" id="address-two" 
                    onChange={(e) => setFormData({ ...formData, address_two: e.target.value })} //TODO: add field
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="group">
                    <label htmlFor="City">City</label>
                    <input type="text" className="form-control" id="city" 
                    onChange={(e) => setFormData({ ...formData, city: e.target.value })} //TODO: add field
                    />
                  </div>
                  <div className="group">
                    <label htmlFor="state">State</label>
                    <input type="text" className="form-control" id="state" 
                    onChange={(e) => setFormData({ ...formData, state: e.target.value })} //TODO: add field
                    />
                  </div>
                  <div className="group">
                    <label htmlFor="state">Zip</label>
                    <input type="text" className="form-control" id="zip"
                    onChange={(e) => setFormData({ ...formData, zip: e.target.value })} //TODO: add field
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="group">
                    <label htmlFor="address-one">What can we help you with?</label>
                    <textarea className="form-control" id="message" rows="3" 
                    onChange={(e) => setFormData({ ...formData, body: e.target.value })}
                    />
                  </div>
                </div>
                <div className="btn-container">
                  <GreenButton title="submit" link={`mailto:sales@consultremotion.com
                  ?subject=Remotion%20Contact%20Form
                  &body=${ //Format email
                    `${formData.body} 
                    %0A -------------------- %0A
                     First Name: ${formData.first_name} %0A
                      Last Name: ${formData.last_name} %0A
                      Email: ${formData.email} %0A
                      Phone: ${formData.phone} %0A
                      Company: ${formData.company} %0A
                      Address 1: ${formData.address_one} %0A
                      Address 2: ${formData.address_two} %0A
                      City: ${formData.city} %0A
                      State: ${formData.state} %0A
                      Zip: ${formData.zip} %0A`
                  }`} />
                </div>

              </form>
            </div>

          </div>
        </div>
      </Section>
    </div>
  )
}

export default ContactUs