import React from 'react'

const ProcessSection = (props) => {
  const { showProcessHeader } = props
  return (
    <div className="ProcessSection" id="process-section">
      {showProcessHeader ? <h1>Our Process is as Easy as...</h1> : <div style={{height: 50}}/>}
      <div className="line-container">
        <div className="line"></div>
      </div>

      <div className="block-container">
        <div className="number-block">
          <span className="number">1</span>
          <span className="text-block">Contact us through
            any of the following:<br />
            • Email us at sales@consultremotion.com<br />
            • Call us at (734) 770-9669<br />
            • Fill out the contact form below
          </span>
        </div>
        <div className="number-block">
          <span className="number">2</span>
          <span className="text-block">Provide a brief span of your application and we’ll assign a qualified engineer to evaluate it</span>
        </div>
        <div className="number-block">
          <span className="number">3</span>
          <span className="text-block">Clear, concise, and timely communication is our goal with every opportunity. We’ll have someone follow up per your direction.</span>
        </div>
      </div>
    </div>
  )
}

export default ProcessSection