
const Section = (props) => {
  const background = props.background ? `url('${props.background}')` : '';

  return (
    <section className={`section ${props.className} ${props.type}`} style={{background}}>
      {props.children}
    </section>
  );
}

export default Section;