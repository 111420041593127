
const Banner = () => {
  return (
    <div className="Banner">
      Call us today to inquire. (734) 770-9669
    </div>
  );
}

export default Banner;

