
const Flex = (props) => {
  return (
    <div className={`flex-container ${props.vertical ? 'vertical' : 'horizontal'}`}>
      {props.children}
    </div>
  );
}

export default Flex;

