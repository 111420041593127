import React from 'react'
import ReactMarkdown from "react-markdown";

const Blog = (props) => {
  const { blogData } = props;

  return (
    <div className="Blog">
      {
        blogData.map((item, index) => {
          let content = item.attributes.Content;
          content = content.replace('](/uploads/', '](https://api.consultremotion.com/uploads/')
          return (
            <div key={index} className="blog-item">
              <div className="top-bar">
                <h3>{item.attributes.Title}</h3>
                <div className="date">
                  <span>{item.attributes.Posted}</span>
                </div>
              </div>
              <div className="content-section">
                <div>
                  <ReactMarkdown children={content} />
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default Blog