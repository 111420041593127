import PageTitleMediaBackground from "../pageTitleMediaBackground/PageTitleMediaBackground";
const PageTitle = (props) => {
  const { title, showBg, bgData, bgType, showDivider } = props;
  return (
    <div className="PageTitle">
      {showBg && <PageTitleMediaBackground type={bgType} source={bgData} showOverlay={true} showDivider={showDivider} position={'absolute'} />}
      <div className="title-container">
        <h1 className="title">{title}</h1>
      </div>
    </div>
  )
}

export default PageTitle