import React from 'react'

//A Blurb is a component that displays a blurb of text with an image next to it for the primary pages.

const Blurb = ({img, text, children}) => {
  return (
    <div className="Blurb">
      <div className="image">
        <img src={img} alt=""/>
      </div>
      <div className="text">
        <span>{text || children}</span>
      </div>
    </div>
  )
}

export default Blurb