import React from 'react'
import { Routes, Route } from 'react-router-dom';

//Routes
import Home from './views/Home/Home';
import AboutUs from './views/AboutUs/AboutUs';
import AutomationInspection from './views/AutomationInspection/AutomationInspection';
import Contact from './views/Contact/Contact';
import ExpertEngineers from './views/ExpertEngineers/ExpertEngineers';
import GrindingSolutions from './views/GrindingSolutions/GrindingSolutions';
import WhatsNew from './views/WhatsNew/WhatsNew';

const Router = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/automation-inspection" element={<AutomationInspection />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/expert-engineers" element={<ExpertEngineers />} />
            <Route path="/grinding-solutions" element={<GrindingSolutions />} />
            <Route path="/whats-new" element={<WhatsNew />} />
        </Routes>
    )
}

export default Router