import GreenButton from "../../components/button/GreenButton";
import MediaBackground from "../../components/mediaBackground/MediaBackground";
import image from '../../public/headers/about-us.png';
import ImageOrVideoWithText from "../../components/imageWithText/ImageOrVideoWithText";
import PageTitle from "../../components/pageTitle/PageTitle";
import meetingPeople from "../../public/meeting-people.png";
import aboutUsVideo from "../../public/Videos/about-us-video.mp4";
import {useNavigate} from 'react-router-dom';
import Section from "../../components/section/Section";
import Flex from "../../components/flex/Flex";
import SectionContainer from "../../components/section/SectionContainer";
import AboutUsVideoPlaceholder from '../../public/about-us-video-placeholder.png'; 

const AboutUs = () => {
  
  return (
    <>
      <div className="AboutUs">
        
        <PageTitle title="About Us" showBg={true} bgData={meetingPeople} bgType='img' />

        <ImageOrVideoWithText src={aboutUsVideo} showBackground={false} mediaType="video" placeholder={AboutUsVideoPlaceholder}>
          <div className="video-text">
            <h2>We're a small, manageable group of experienced engineers with an intense desire to do deliver exceptional manufacturing solutions.</h2>
            <p>We endeavor to design and build systems that are not only mechanically well thought out, but also incorporate 'best practice' controls engineering with a focus on serviceability, data collection, and adaptive learning.</p>
            <GreenButton title="Learn More About Our Expert Engineers" to={'/expert-engineers'}/>
          </div>
        </ImageOrVideoWithText>

        <Section className="our-mission">
          <MediaBackground type="image" source={image} showDivider={true} pos="relative" showOverlay={true}>
            <SectionContainer>
              <Flex>
                <div className="left-side">
                  <h2>OUR MISSION</h2>
                  <div>To be an industry leading supplier of high-performance manufacturing systems for the precision components market.  For over a decade, we have focused on innovative, best-practice manufacturing strategies while delivering highly efficient manufacturing solutions.  Our team understands the detail and urgency required to succeed in this industry. </div>
                </div>
                <div className="right-side">
                  <h2>EXPERIENCE MATTERS</h2>
                  <div>We've worked with each of our three core machine tool partners for several years.  This history combined with our internal group of engineers gives us decades of 'know-how' and manufacturing expertise. </div>
              
                  <h2>PARTNER STABILITY</h2>
                  <div>We have aligned ourselves with like-minded machine tool partners and have thrived with each for multiple years.  The long history with each has created strong trust allowing us to pass along the collective knowledge to our customers. </div>
              
                </div>
              </Flex>
            </SectionContainer>
          </MediaBackground>
        </Section>

        <div className="section">
        </div>
      </div>
    </>
  )
}

export default AboutUs
