import React from 'react'
import { Link } from 'react-router-dom';

const GreenButton = (props) => {
  const {to, link, title, onClick} = props;

  if (to) {
    return <Link to={to}>
      <div className={`GreenButton ${props.fullWidth ? '' : 'small'}`}>
        <span>{title}</span>
      </div>
    </Link>
  }

  if(link) {
    return <a href={link}>
      <div className={`GreenButton ${props.fullWidth ? '' : 'small'}`}>
        <span>{title}</span>
      </div>
    </a>
  }
  return (
    <div className={`GreenButton ${props.fullWidth ? '' : 'small'}`} onClick={onClick}>
      <span>{title}</span>
    </div>
  )
}

export default GreenButton