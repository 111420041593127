import './App.scss';

//Components
import Banner from './components/banner/Banner';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';

import Router from './Router';

function App() {
  return (

    <div className="App">
      <Header />
      <Banner />
      <Router />
      <Footer />
    </div>
  );
}

export default App;
