// import MediaBackground from "../../components/mediaBackground/MediaBackground";
import PRODUCTION_INSPECTION_FIRST_ARTICLE_INSPECTIONBgImage from '../../public/headers/PRODUCTION INSPECTION FIRST ARTICLE INSPECTION.png';
import PRODUCTION_AUTOMATIONBgImage from '../../public/headers/PRODUCTION AUTOMATION.png';
import FLEXIBLE_MACHINE_TENDING_AUTOMATIONBgImage from '../../public/headers/FLEXIBLE MACHINE  TENDING AUTOMATION.png';
import ROLLINGBgImage from '../../public/headers/ROLLING.png';
import automationInspection from "../../public/Videos/automation-inspection-video.mp4";
import AutomationCapabilitiesImage from '../../public/AutomationCapabilities.png';
import AutomationIcon from '../../public/AutomationIcon.png';
import Section from "../../components/section/Section";
import PageTitle from "../../components/pageTitle/PageTitle";
import Blurb from "../../components/blurb/Blurb";
import SectionContainer from "../../components/section/SectionContainer";
import ImageOrVideoWithText from "../../components/imageWithText/ImageOrVideoWithText";
import CardSection from "../../components/cardSection/CardSection";

const AutomationInspection = () => {
  return (
    <>
      <div className="AutomationInspection">
        <PageTitle title="Automation & Inspection" showBg={true} bgType="video" bgData={automationInspection} />

        <Section>
          <SectionContainer>
            <Blurb img={AutomationIcon}>
              From dedicated mass-production manufacturing cells to highly flexible, mobile machine tending stations, we have the experience to solve your automation and inspection needs.
            </Blurb>
          </SectionContainer>
        </Section>

        <Section>
          <ImageOrVideoWithText src={AutomationCapabilitiesImage}>
            <SectionContainer>
              <h2>CAPABILITIES</h2>
              <ul>
                <li>Rotary dial high speed inspection</li>
                <li>First article benchtop inspection </li>
                <li>Gantry and robotic load/unload</li>
                <li>Mobile machine tending stations</li>
                <li>Easy to learn/program mobile robot stations</li>
                <li>Thread & Spine Rolling</li>
              </ul>
            </SectionContainer>
          </ImageOrVideoWithText>
        </Section>

        <div className="section">
          <CardSection Title="Core Strengths - Automation & Inspection" CardData={[
            {
              title: "PRODUCTION AUTOMATION",
              subtitle: "",
              image: PRODUCTION_AUTOMATIONBgImage,
            },
            {
              title: "FLEXIBLE MACHINE TENDING AUTOMATION",
              subtitle: "",
              image: FLEXIBLE_MACHINE_TENDING_AUTOMATIONBgImage,
            },
            {
              title: "PRODUCTION INSPECTION & FIRST ARTICLE INSPECTION",
              subtitle: "",
              image: PRODUCTION_INSPECTION_FIRST_ARTICLE_INSPECTIONBgImage,
            },
            {
              title: "ROLLING",
              subtitle: "",
              image: ROLLINGBgImage,
            },
          ]} />
        </div>
      </div>
    </>
  )
}

export default AutomationInspection
