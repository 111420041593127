import React from 'react'
import dividerbg from '../../public/divider-with-triangle.png'

const TriangleDivider = () => {
  return (
    <div className="TriangleDivider" style={{}}>
      <img alt="divider" src={dividerbg} />
    </div>
  )
}

export default TriangleDivider