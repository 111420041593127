import React from 'react'
import MediaBackground from '../mediaBackground/MediaBackground'
import overlapBg from '../../public/overlap-bg.png'

//Image to the left, text to the right. Goes in default views as a "section"

const ImageOrVideoWithText = (props) => {
  let { src, mediaType, children, showBackground = true, placeholder } = props
  return (
    <>
      <div className="ImageOrVideoWithText">
        {showBackground && <MediaBackground type="image" source={overlapBg} showOverlay={true} pos={"absolute"} />}
        <div className="inner-container">
          <div className="image-section video-section">
            {
              mediaType == "video" ? <div className="video-player">
                <video
                  src={src}
                  controls
                  playsInline
                  poster={placeholder}
                ></video>
              </div>
              :
              <img src={src} alt="" />
            }
          </div>
          <div className="text-section">
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export default ImageOrVideoWithText